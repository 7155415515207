<script setup>
  const top = useTop()
</script>

<template>
  <div class="font-main text-base text-tarawera-900">
    <header class="fixed top-0 z-50 h-16 w-full bg-tarawera-900 text-white shadow duration-500" :class="[top ? 'md:h-20' : 'md:h-16']">
      <div class="mx-auto max-w-screen-xl px-4">
        <NuxtLink to="/">
          <NuxtImg
            alt="Logo Profil Public"
            src="/images/logo-profil-public-600x600.webp"
            :class="['mt-1 aspect-square size-20 rounded-full duration-500 md:mt-2', top ? 'md:size-24' : 'md:size-20']"
          />
        </NuxtLink>
      </div>
    </header>

    <main :class="['mt-16 duration-500', top ? 'md:mt-20' : 'md:mt-16']">
      <slot />
    </main>
  </div>
</template>
